// extracted by mini-css-extract-plugin
export var recentArticles = "cz_qr";
export var recentArticles__category = "cz_qD";
export var recentArticles__img = "cz_qv";
export var recentArticles__link = "cz_qt";
export var recentArticles__post = "cz_qs";
export var recentArticles__postClamp = "cz_qC";
export var recentArticles__postDate = "cz_qx";
export var recentArticles__postDescriprion = "cz_qB";
export var recentArticles__postInfo = "cz_qw";
export var recentArticles__postStats = "cz_qy";
export var recentArticles__postTitle = "cz_qz";
export var recentArticles__title = "cz_qF";